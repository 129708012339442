import { axiosSingleton } from '../plugins/axios'

export function getListApiBloc () {
    return axiosSingleton.getInstance().get(`api/GetIpbloc`)
}

export function cerrarApiBloc (element) {
    console.log(element);

    return axiosSingleton.getInstance().put(`api/cerrarIP/${element.id_blk}`)
}

export function insertProfesion (element) {
    console.log(element);

    return axiosSingleton.getInstance().get(`api/AddProfesion/${element}`)
}






