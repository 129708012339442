import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "../views/bootstrap/Dashboard.vue";
import DefaultLayout from '@/layouts/Index.vue'
import store from '../store'
//import tikets from '../views/Tikets.vue'

const routes = [
  {
    path: "/",
    name: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Principal",
        component: () => import('@/views/Dashboard.vue'),
        props: { default: true },
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/registros",
        name: "Módulo de bienes",
        component: () => import('@/views/Bienes.vue'),
        props: { default: true },
        meta: {
          requireAuth: true,
        },
      },
      {
        // path: '/content/:value?',
        path: '/content/:name',
        name: 'TEMAS',
        props: { default: true },
        component: () => import('@/views/oldsia/ContentSIA'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/auditorias",
        name: "Módulo de atención de auditorías",
        component: () => import('@/views/Auditorias.vue'),
        props: { default: true },
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/polizas",
        name: "Pólizas",
        component: () => import('@/views/Polizas.vue'),
        props: { default: true },
        meta: {
          requireAuth: true,
        },

      },
      {
        path: '/vehiculos',
        name: "Veh�culos",
        component: () => import('@/views/Vehiculos.vue'),
        hidden: false,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '/temas',
        name: "Temas",
        component: () => import('@/views/Temas.vue'),
        hidden: false,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '/tickets',
        name: "Tickets",
        component: () => import('@/views/Tikets.vue'),
        hidden: false,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '/licencias',
        name: "Licencias",
        component: () => import('@/views/Licencias.vue'),
        hidden: false,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '/adminmural',
        name: "AdminMural",
        component: () => import('@/views/AdminMural.vue'),
        hidden: false,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '/busquedaInformacion',
        name: "Módulo Búsquedas Información ",
        component: () => import('@/views/BusquedaInformacion.vue'),
        hidden: false,
      },

    ]
  },
  {
    name: 'SignIn',
    path: '/signin',
    component: () => import('@/views/auth/SignIn.vue'),
    hidden: true,
  },
  {
    path: '/registration',
    component: () => import('@/views/auth/SignUp.vue'),
    hidden: true,
  },
  {
    path: '/mantenimiento',
    component: () => import('@/views/Mantenimiento.vue'),
    hidden: false,
  },
  {
    path: '/mural',
    component: () => import('@/views/public/Mural.vue'),
    hidden: false,
  },
  {
    path: '/eventos',
    component: () => import('@/views/public/Eventos.vue'),
    hidden: false,
  },
  {
    path: '/evento2',
    component: () => import('@/views/public/evento2.vue'),
    hidden: false,
  },
  {
    path: '/indicadores',
    component: () => import('@/views/public/Indicadores.vue'),
    hidden: false,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {

  if (to.href == "/signin" && await store.getters['user/isLoggedIn']) {
    next('/')
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    // console.log('logueadodd', store.getters)
    if (await store.getters['user/isLoggedIn']) {

      // console.log('before 1',this.$globalData)
      // console.log('before', to)

      // var url = to.href;
      // var splitter = '/content/'
      // var indexOf = url.indexOf(splitter);

      // console.log(url.slice(indexOf + splitter.length));
      if(to.params.name){
        // console.log(to.params.name);
        to.name = to.params.name
      }
      
      next()
      return
    }
    next('/signin')
  } else {
    next()
  }
})

export default router;
